.shopName {
    font-size: var(--h5-font-size);
    font-weight: var(--medium-bold);
}

:global(.mobile) {
    .shopIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: var(--xxl-spacing) var(--m-spacing);
        margin-bottom: 0;

        .icon {
            height: 70px;
            width: 70px;
            border-radius: 20px;
            margin-right: var(--m-spacing);
        }
    }
}

:global(.desktop) {
    .shopIcon {
        display: flex;
        flex-direction: row;
        padding: 30px 0;
        background: #FAFAFA;
        justify-content: center;
        align-items: center;
        margin-top: var(--xxl-spacing);

        .main {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        img.icon {
            height: 162px;
            width: 290px;
            border-radius: 50px;
        }

        .shopIconGrid {
            margin-left: var(--xl-spacing);
        }
    }
}


.body {
    display: flex;
    flex-direction: row;
}

.left {
    width: 100%;
    height: 100%;
    position: sticky;
    top: 0;
    flex: 0.22;
}

.right {
    flex: 0.36;
    width: 100%;
    height: 100%;
    position: sticky;
    top: 0;
}

.center {
    @media (--desktop) {
        flex: 0.42;
    }
}

.viewAll {
    border-bottom: 0 !important;
}


.overflow1 {
    display: flex;
    margin-top: var(--xxxxxxl-spacing);
}