
.catalogs {
    border-top: 1px solid var(--black15);
    padding: 40px 20px;

    @media (--phone) {
        border: 0;
        padding-top: var(--xxl-spacing);
        padding-bottom: var(--s-spacing);
    }

    display: flex;
    flex-direction: row;

    .slideButton:first-child {
        margin-right: var(--l-spacing);
    }

    .slideButton:last-child {
        margin-left: var(--l-spacing);
    }
}

.allowLeft {
    margin-left: auto !important;
}


.catalog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    aspect-ratio: 1/1.4;

    .catalogImg, .noCatalogImg {
        width: 142px;
        height: 142px;

        @media (--phone) {
            width: 98px;
            height: 98px;
        }
    }

    .catalogImg, .catalogMore {
        border-radius: 50px;
        color: var(--black);
        font-size: var(--h4-font-size);
        font-weight: var(--semi-bold);
        object-fit: contain;

        @media (--phone) {
            border-radius: 20px;
        }
    }

    .catalogLabel {
        @apply line-clamp-2 min-h-[4ch];
        margin-top: var(--s-spacing);
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--black80);
        text-overflow: ellipsis !important;
    }
}


.catalogMore {
    background: #F4F4F4 !important;
    height: 142px;
    width: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;

    @media (--phone) {
        width: 98px;
        height: 98px;
        border-radius: 20px;
    }
}

.templates {

    @media (--phone) {
        margin-top: var(--xxl-spacing);
    }

    .slideButton {
        position: relative;
        z-index: 10;

        &:last-child {
            top: -120px;
            left: calc(100% - 40px);
        }

        &:first-child {
            top: 120px;
            left: 5px;
        }
    }
}

.template {
    width: 100%;

    .templateImage {
        height: 200px;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: 100% 100%;
        align-items: center;
        @media (--desktop) {
            height: 200px;
            margin: auto;
            padding: 0 var(--xxl-spacing);
        }
    }

    .tplTitle {
        font-weight: var(--semi-bold);
        font-size: var(--h4-font-size);
        color: var(--secondary);
        padding-bottom: var(--s-spacing);
        text-align: center;
    }

    .tplDesc {
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--secondary);
        text-align: center;
    }
}

.heading {
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
    margin: var(--xxl-spacing) var(--xl-spacing) var(--l-spacing);
}

.single {
    margin-right: 0 !important;
    width: 100% !important;
}
