span.show-more-less-clickable {
    cursor:pointer;
    text-decoration:underline;
}

span.show-more-less-clickable:hover{
    text-decoration:none;
}

.common_catalogs__kRH1v {
    border-top: 1px solid var(--black15);
    padding: 40px 20px
}

@media screen and (max-width: 419px) {

.common_catalogs__kRH1v {
        border: 0;
        padding-top: var(--xxl-spacing);
        padding-bottom: var(--s-spacing)
}
    }

.common_catalogs__kRH1v {

    display: flex;
    flex-direction: row;
}

.common_catalogs__kRH1v .common_slideButton__cQ_e2:first-child {
        margin-right: var(--l-spacing);
    }

.common_catalogs__kRH1v .common_slideButton__cQ_e2:last-child {
        margin-left: var(--l-spacing);
    }

.common_allowLeft__3Y1kO {
    margin-left: auto !important;
}


.common_catalog__lSFII {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    aspect-ratio: 1/1.4;
}


.common_catalog__lSFII .common_catalogImg__rUlzJ, .common_catalog__lSFII .common_noCatalogImg__9Hwar {
        width: 142px;
        height: 142px
    }


@media screen and (max-width: 419px) {

    .common_catalog__lSFII .common_catalogImg__rUlzJ, .common_catalog__lSFII .common_noCatalogImg__9Hwar {
            width: 98px;
            height: 98px
    }
        }


.common_catalog__lSFII .common_catalogImg__rUlzJ, .common_catalog__lSFII .common_catalogMore__dCMVj {
        border-radius: 50px;
        color: var(--black);
        font-size: var(--h4-font-size);
        font-weight: var(--semi-bold);
        -o-object-fit: contain;
           object-fit: contain
    }


@media screen and (max-width: 419px) {

    .common_catalog__lSFII .common_catalogImg__rUlzJ, .common_catalog__lSFII .common_catalogMore__dCMVj {
            border-radius: 20px
    }
        }


.common_catalog__lSFII .common_catalogLabel__ViDGZ {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    min-height: 4ch;
        margin-top: var(--s-spacing);
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--black80);
        text-overflow: ellipsis !important
}


.common_catalogMore__dCMVj {
    background: #F4F4F4 !important;
    height: 142px;
    width: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px
}


@media screen and (max-width: 419px) {


.common_catalogMore__dCMVj {
        width: 98px;
        height: 98px;
        border-radius: 20px
}

.common_templates__Nqv_Z {
        margin-top: var(--xxl-spacing)
}
    }

.common_templates__Nqv_Z .common_slideButton__cQ_e2 {
        position: relative;
        z-index: 10
    }

.common_templates__Nqv_Z .common_slideButton__cQ_e2:last-child {
            top: -120px;
            left: calc(100% - 40px);
        }

.common_templates__Nqv_Z .common_slideButton__cQ_e2:first-child {
            top: 120px;
            left: 5px;
        }

.common_template__p_PAA {
    width: 100%;
}

.common_template__p_PAA .common_templateImage__yew0Z {
        height: 200px;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: 100% 100%;
        align-items: center
    }

@media screen and (min-width: 420px) {

    .common_template__p_PAA .common_templateImage__yew0Z {
            height: 200px;
            margin: auto;
            padding: 0 var(--xxl-spacing)
    }
        }

.common_template__p_PAA .common_tplTitle__4_yHD {
        font-weight: var(--semi-bold);
        font-size: var(--h4-font-size);
        color: var(--secondary);
        padding-bottom: var(--s-spacing);
        text-align: center;
    }

.common_template__p_PAA .common_tplDesc__1BxSj {
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);
        color: var(--secondary);
        text-align: center;
    }

.common_heading__LTGnV {
    font-size: var(--d-font-size);
    font-weight: var(--semi-bold);
    color: var(--black);
    margin: var(--xxl-spacing) var(--xl-spacing) var(--l-spacing);
}

.common_single__2nSQF {
    margin-right: 0 !important;
    width: 100% !important;
}

.common_viewAll__31JsP {
    padding: var(--l-spacing) var(--m-spacing);
 }
@media screen and (min-width: 420px) {
    .common_viewAll__31JsP {
        padding: 40px 40px;
 }
    }
.common_viewAll__31JsP .common_head__gaHhc {
        margin: var(--l-spacing) 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
.common_viewAll__31JsP .common_name__uBZOs {
        font-size: var(--h4-font-size);
        font-weight: var(--semi-bold);
        color: var(--black);
    }
.common_viewAll__31JsP .common_viewAllTop__VRNRB {
        font-size: var(--h5-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
    }
.common_viewAll__31JsP .common_viewAllTop__VRNRB span {
            margin-right: var(--s-spacing);
        }
.common_viewAll__31JsP .common_footer__MMjiX {
        margin: var(--l-spacing) 0;
        font-weight: var(--semi-bold);
        text-align: center;
    }
.common_viewAll__31JsP .common_footer__MMjiX span {
            font-size: var(--d-font-size);
            color: var(--primary);
            margin-right: var(--s-spacing);
        }
.common_viewAll__31JsP {
 
 
    border-bottom: 1px solid var(--black15);
    padding-bottom: var(--m-spacing);
}
 

.BottomDrawer_container__vWUHR {
    margin-top: var(--l-spacing);
}

.BottomDrawer_heading__oy8cz {
    font-size: var(--h4-font-size);
    color: var(--black);
    font-weight: var(--semi-bold);
}

.BottomDrawer_paper__fmVHr {
    border-radius: 20px 20px 0 0;
    max-height: 80% !important;
    overflow: hidden;
}

.BottomDrawer_overflow__kqhvU {
    overflow:  hidden !important;
    padding: 16px !important
}

.BottomDrawer_row__On4x_ {
    display: flex;
    align-items: center;
    align-content: center;
}

.BottomDrawer_closeIcon__OUdqI {
    padding-right: 12px;
}

.BottomDrawer_fIcon__RersA {
    margin-left: 10px;
        margin-top: 8px;
}

.BottomDrawer_sIcon__rDlPA {
    margin-left: 10px;
    margin-top: 4px;
}

.BottomDrawer_cIcon__mPEq_ {
       position: fixed;
    right: 20px; 
    padding-top: 4px;
}

.BottomDrawer_bottom__0WzFz {
    padding-bottom: 52px !important;
}
.PopUp_container__CZ3Q2 {
    margin-top: var(--l-spacing);
    overflow-y: auto;
    height: 80%;
}

.PopUp_heading__a9_5x {
    font-size: var(--d-font-size);
    font-weight: var(--normal);
    color: var(--primary);
}

.PopUp_paper__8jFFh {
    border-radius: 20px 20px 0 0;
    max-height: 80% !important;
    overflow: hidden;
}

.PopUp_overflow__HUAUG {
    overflow: hidden !important;
    padding: 16px !important;
}

.PopUp_row__EDwQ4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PopUp_closeIcon__0fyIF {
    padding-right: 12px;
    color: var(--primary);
}

.PopUp_fIcon__oBEuE {
    margin-left: 10px;
}

.AddButton_add__6H71B {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 6px !important;
}


    .AddButton_add__6H71B span {
        font-size: var(--d-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
        margin: 0 var(--s-spacing);
    }


    .AddButton_add__6H71B .AddButton_minus__5hYU9 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 20px;
        margin-right: var(--s-spacing);
    }


    .AddButton_add__6H71B .AddButton_addIcon__iSJ1e {
        margin-left: var(--s-spacing);
    }



.AddButton_salvia__6JmXm.AddButton_addButton__1UgJr {
    border-radius: 0;
    width: 100%;
    padding: var(--s-spacing) 0px
}



@media screen and (min-width: 420px) {



.AddButton_salvia__6JmXm.AddButton_addButton__1UgJr {
        width: 326px
}
    }



.AddButton_salvia__6JmXm.AddButton_addButton__1UgJr .AddButton_plus__3biCo, .AddButton_salvia__6JmXm.AddButton_addButton__1UgJr .AddButton_minus__5hYU9, .AddButton_salvia__6JmXm.AddButton_addButton__1UgJr .AddButton_addIcon__iSJ1e, .AddButton_salvia__6JmXm.AddButton_addButton__1UgJr .AddButton_add__6H71B svg {
        display: none !important;
    }
 
 
 .AddButton_lupine__E8j1B.AddButton_addButton__1UgJr {
    border-radius: 0;
    width: 100%
 }
 
 
 @media screen and (min-width: 420px) {
 
 
 .AddButton_lupine__E8j1B.AddButton_addButton__1UgJr {
        width: 234px;
        margin-bottom: 10px
 }
    }
 
 
 .AddButton_lupine__E8j1B.AddButton_addButton__1UgJr .AddButton_plus__3biCo, .AddButton_lupine__E8j1B.AddButton_addButton__1UgJr .AddButton_minus__5hYU9, .AddButton_lupine__E8j1B.AddButton_addButton__1UgJr .AddButton_addIcon__iSJ1e, .AddButton_lupine__E8j1B.AddButton_addButton__1UgJr .AddButton_add__6H71B svg {
        display: none !important;
    }


 .AddButton_addButton__1UgJr {
    border: 1px solid var(--primary);
    display: inline-flex;
    min-width: 88px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    border-radius: var(--s-border-radius);
 }


 .AddButton_variantList__fArSz {
    border-bottom: 1px solid var(--black15);
 }


 .AddButton_variantContainer__8O8a0 .AddButton_key__dovuw {
        font-size: var(--d-font-size);
        font-weight: var(--medium-bold);
        color: var(--black);
        margin-bottom: var(--l-spacing);
    }


 .AddButton_variantContainer__8O8a0 .AddButton_buttons__4gm3S {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }


 .AddButton_variantContainer__8O8a0 .AddButton_buttons__4gm3S button {
            margin: 0 var(--s-spacing);
            height: 52px;
        }


 .AddButton_variants__9b2FN .AddButton_variant__daEQr {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        margin-bottom: var(--l-spacing);
        color: var(--black60);
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size)
    }


 .AddButton_variants__9b2FN .AddButton_variant__daEQr.AddButton_selected__L_38I {
            color: var(--black);
            font-weight: var(--semi-bold);
        }


 .AddButton_variants__9b2FN .AddButton_variant__daEQr .AddButton_variantName__oK_b2 {
            margin-left: var(--s-spacing);
        }


 .AddButton_variants__9b2FN .AddButton_variant__daEQr .AddButton_radio__ve_Hu {
            display: flex;
            flex-direction: row;
            align-items: center;
        }


 .AddButton_colorBox__1otJ1 {
    margin: var(--l-spacing) 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
 }


 .AddButton_colorBox__1otJ1 .AddButton_color__HiXje {
        border-radius: 10px;
        width: 35px;
        height: 35px;
        cursor: pointer;
        margin-right: var(--l-spacing);
        margin-bottom: var(--l-spacing);
    }


 .AddButton_colorBox__1otJ1 .AddButton_color__HiXje .AddButton_check__gOGVM {
            position: relative;
            left: 16px;
            top: -6px;
        }


 .AddButton_disabled__lLIiJ {
    opacity: 0.5;
    pointer-events: none;
    touch-action: none;
    z-index: -1;
 }

.ProductColors_outer__8iSRi {
    width: 20px;
    height: 20px;
    margin-right: 16px !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ProductColors_outline__pVIda {
    outline-offset: 2px;
    outline-style: solid !important;
}

.ProductColors_link__kywcM {
    font-size: var(--d-font-size);
    border-bottom: 1px solid;
    cursor: pointer;
}

.ProductTags_container__PQEmU {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ProductTags_button__yeRpH {
    border-radius: var(--border-radius);
    padding: var(--xs-spacing);
    margin: var(--xxs-spacing) var(--s-spacing) var(--xs-spacing) 0;
    cursor: pointer;
}
.GetPrice_decimal__248ah {
    font-size: var(--xxs-font-size) !important;
    font-weight: var(--medium-bold) !important;
}

.ProductCard_main__t1Q6O {
    border-bottom: 1px solid var(--black15);
    margin-bottom: var(--m-spacing);
    padding-bottom: var(--xl-spacing)
}

@media screen and (max-width: 419px) {

.ProductCard_main__t1Q6O {
        padding-bottom: var(--l-spacing)
}
    }

.ProductCard_container__EjgJj {
    display: flex;
    flex-direction: row
}

@media screen and (min-width: 420px) {

.ProductCard_container__EjgJj {
        align-items: center
}
    }


.ProductCard_right__siiE0 {
    display: flex;
    flex-direction: column;
    align-items: center
}


@media screen and (min-width: 420px) {


.ProductCard_right__siiE0 {
        min-width: 108px;
        padding-bottom:0.25rem
}
    }


@media screen and (max-width: 419px) {


.ProductCard_right__siiE0 {
        min-height: 100px;
        justify-content: center
}
    }

.ProductCard_imgContainer__HauCf {
    width: 110px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--s-spacing);
    border: 1px solid var(--black05) !important;
    border-radius: var(--s-border-radius);
    position: relative
}

@media screen and (min-width: 420px) {

.ProductCard_imgContainer__HauCf {
        height: 110px;
        border-radius: 15px
        /*z-index: -99999 !important*/
}
    }

.ProductCard_oos__chaPn {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px
}

@media screen and (min-width: 420px) {

.ProductCard_oos__chaPn {
        border-radius: 5px;
        padding: var(--s-spacing) var(--xl-spacing)
}
    }

.ProductCard_img__Qis6p {
    width: 108px;
    height: 88px;
    -o-object-fit: cover;
       object-fit: cover
}

@media screen and (min-width: 420px) {

.ProductCard_img__Qis6p {
        height: 110px;
        width: 110px;
        border-radius: 15px
}
    }

.ProductCard_click__bmzIZ {
    flex: 1 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: var(--l-spacing);
}

.ProductCard_name__ZUPrm {
    margin-top: var(--s-spacing);
    font-weight: var(--medium-bold);
    font-size: var(--d-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
    text-align: left;
}

.ProductCard_price__SHHQI {
    margin-bottom: var(--s-spacing);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ProductCard_amount__P84B6,
.ProductCard_discount___WaZJ {
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--semi-bold);
    margin-right: var(--s-spacing);
}

.ProductCard_originalAmount__vMPQS {
    font-size: var(--s-font-size);
    color: var(--black50);
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    margin-right: var(--s-spacing);
}

.ProductCard_discount___WaZJ {
    color: var(--green);
    background: var(--green20);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    padding: 2px var(--s-spacing);
}

.ProductCard_unit__SnJqg {
    color: var(--black50);
    text-transform: capitalize;
    margin-bottom: var(--m-spacing);
}

.ProductCard_flexRight__Z0fLV {
    display: flex;
    flex-direction: row;
    justify-content: flex-end
}

@media screen and (min-width: 420px) {

.ProductCard_flexRight__Z0fLV {
        margin-right: 10px
}
    }



.NoOrders_text__GS_qe {
    color: var(--black50);
    padding-top: 20px;
}

.Cart_cart__kS9ot {
    font-weight: var(--semi-bold);
    font-size: 32px;
    color: var(--black);
    padding-top: 40px;
}

.Cart_container__ennRR {
    margin-bottom: 50px;
}

.Cart_borderLeft__fva_M {
    border-left: 1px solid var(--black15);
    padding-left: 50px;
}

.Cart_items__nywvg {
    color: var(--black60);
    font-size: var(--d-font-size);
    font-weight: var(--medium-bold);
    margin-top: var(--m-spacing);
    margin-bottom: var(--xl-spacing);
}

.Cart_productLeft__Y5ntx {
    display: flex;
    flex-direction: column;
    flex: 0.8 1;
}

.Cart_product__8S7OG {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--l-spacing);
}

.Cart_pricing__VVwyO {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.Cart_title__InuZc {
    font-weight: var(--medium-bold);
    font-size: var(--d-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
}

.Cart_amount__njBQp {
    font-weight: var(--semi-bold);
    font-size: var(--d-font-size);
    color: var(--black);
}

.Cart_originalAmount__XDcnS {
    font-weight: var(--medium-bold);
    font-size: var(--m-font-size);
    margin: 0 var(--s-spacing);
    color: #FF4545;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
}

.Cart_discount__SnmBc {
    font-weight: var(--medium-bold);
    font-size: var(--m-font-size);
    color: var(--green);
}

.Cart_unit__HilXi {
    margin-top: var(--xs-spacing);
    font-size: var(--m-font-size);
    color: var(--black60);
}

.Cart_add__b5C_l {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Cart_add__b5C_l span {
        font-size: var(--d-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
        margin: 0 var(--s-spacing);
    }

.Cart_add__b5C_l .Cart_minus__Zh1cy {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 20px;
    }

.Cart_addButton__Qb2Xi {
    border: 1px solid var(--primary);
    display: inline-flex;
    min-width: 68px;
    min-height: 24px;
    align-items: center;
    justify-content: center;
    border-radius: var(--s-border-radius);
}


.Cart_bottom__R3HT6 {
    padding-left: 50px;
    margin-top: var(--xl-spacing);
}

.Cart_subTotal__6nUEz {
    font-weight: var(--semi-bold);
    font-size: var(--h4-font-size);
    color: var(--black);
    display: flex;
    flex-direction: row;
}

.Cart_subTotal__6nUEz div:first-child {
        flex: 0.8 1;
    }

.Cart_extraCharges__N6RM4 {
    color: var(--black60);
    font-size: var(--m-font-size);
    font-weight: var(--medium-bold);
    margin-top: var(--s-spacing);
    margin-bottom: var(--xxxxl-spacing);
}

.Cart_button__YRxRL {
    margin-left: 30px;
    width: 80%;
}

.NavBar_container__M4xwK {
    padding-right: 50px;
    border-right: 1px solid var(--black15);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 40px;
    margin-bottom: 40px;
}


.NavBar_shop__gc7ZO {
    display: block;
    font-weight: var(--medium-bold) !important;
    color: var(--black) !important;
    font-size: var(--h5-font-size) !important;
}

.NavBar_collections__YbWAg {
    margin-top: var(--xl-spacing);
    font-size: var(--h5-font-size) !important;
    font-weight: var(--medium-bold) !important;
    display: block;
}

.NavBar_collection__3uMjn {
    margin-top: var(--m-spacing);
    font-size: var(--d-font-size) !important;
    color: var(--black60) !important;
}


.NavBar_selected__L9ebw {
    color: var(--primary) !important;
    font-weight: var(--bold) !important;
}

.Skeleton_container__Pu6xx {
    padding: var(--s-spacing) var(--m-spacing);
}

.Skeleton_main__2A792 {

}

.Skeleton_flashCard__QhXos {

}

.Skeleton_first__m_3FN {

}

.Skeleton_second__UakWt {

}

.DisablePopup_dialog__N9d4w{
    /* width: 586px !important; */
    height: 363px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important
}

@media screen and (max-width: 419px) {

.DisablePopup_dialog__N9d4w{
        font-size: var(--m-font-size);
        width: 100% !important;
        height: 11rem !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important
}
     }

.DisablePopup_image__dVuDn{
    text-align: center;
}

.DisablePopup_img__eoI9z{

    height:  76px;
    width:  76px
}

@media screen and (max-width: 419px) {

.DisablePopup_img__eoI9z{
        height:  42px !important;
        width:  42px !important
}
     }

.DisablePopup_content__JafNB{
    /* height: 87px; */
    margin-top: 28px

}

@media screen and (max-width: 419px) {

.DisablePopup_content__JafNB{
        margin-top: 18px

} 
    }

.DisablePopup_wrnText__2k7io{

    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding-left:20%;
    padding-right:20%;
    color: #212121
}

@media screen and (max-width: 419px) {

.DisablePopup_wrnText__2k7io{
        font-size: 12px !important;
        line-height: 16px !important
}
     }
.store_shopName__WHECu {
    font-size: var(--h5-font-size);
    font-weight: var(--medium-bold);
}

.mobile .store_shopIcon___aPoh {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: var(--xxl-spacing) var(--m-spacing);
        margin-bottom: 0;
    }

.mobile .store_shopIcon___aPoh .store_icon__rg5W5 {
            height: 70px;
            width: 70px;
            border-radius: 20px;
            margin-right: var(--m-spacing);
        }

.desktop .store_shopIcon___aPoh {
        display: flex;
        flex-direction: row;
        padding: 30px 0;
        background: #FAFAFA;
        justify-content: center;
        align-items: center;
        margin-top: var(--xxl-spacing);
    }

.desktop .store_shopIcon___aPoh .store_main__7aAHD {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

.desktop .store_shopIcon___aPoh img.store_icon__rg5W5 {
            height: 162px;
            width: 290px;
            border-radius: 50px;
        }

.desktop .store_shopIcon___aPoh .store_shopIconGrid__3llJP {
            margin-left: var(--xl-spacing);
        }


.store_body__US3wi {
    display: flex;
    flex-direction: row;
}

.store_left__6hXCx {
    width: 100%;
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    flex: 0.22 1;
}

.store_right__6E9af {
    flex: 0.36 1;
    width: 100%;
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

@media screen and (min-width: 420px) {

.store_center__8eN20 {
        flex: 0.42 1
}
    }

.store_viewAll__b_bMa {
    border-bottom: 0 !important;
}


.store_overflow1__ve4Uj {
    display: flex;
    margin-top: var(--xxxxxxl-spacing);
}
