.container {
    padding-right: 50px;
    border-right: 1px solid var(--black15);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 40px;
    margin-bottom: 40px;
}


.shop {
    display: block;
    font-weight: var(--medium-bold) !important;
    color: var(--black) !important;
    font-size: var(--h5-font-size) !important;
}

.collections {
    margin-top: var(--xl-spacing);
    font-size: var(--h5-font-size) !important;
    font-weight: var(--medium-bold) !important;
    display: block;
}

.collection {
    margin-top: var(--m-spacing);
    font-size: var(--d-font-size) !important;
    color: var(--black60) !important;
}


.selected {
    color: var(--primary) !important;
    font-weight: var(--bold) !important;
}
