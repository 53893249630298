.add {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 6px !important;


    span {
        font-size: var(--d-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
        margin: 0 var(--s-spacing);
    }


    .minus {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 20px;
        margin-right: var(--s-spacing);
    }


    .addIcon {
        margin-left: var(--s-spacing);
    }
}



.salvia.addButton {
    border-radius: 0;
    width: 100%;
    padding: var(--s-spacing) 0px;

    @media (--desktop) {
        width: 326px;
    }

    .plus, .minus, .addIcon, .add svg {
        display: none !important;
    }
}
 
 
 .lupine.addButton {
    border-radius: 0;
    width: 100%;

    @media (--desktop) {
        width: 234px;
        margin-bottom: 10px;
    }


    .plus, .minus, .addIcon, .add svg {
        display: none !important;
    }
 }


 .addButton {
    border: 1px solid var(--primary);
    display: inline-flex;
    min-width: 88px;
    min-height: 28px;
    align-items: center;
    justify-content: center;
    border-radius: var(--s-border-radius);
 }


 .variantList {
    border-bottom: 1px solid var(--black15);
 }


 .variantContainer {
    .key {
        font-size: var(--d-font-size);
        font-weight: var(--medium-bold);
        color: var(--black);
        margin-bottom: var(--l-spacing);
    }


    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;


        button {
            margin: 0 var(--s-spacing);
            height: 52px;
        }
    }
 }


 .variants {
    .variant {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        margin-bottom: var(--l-spacing);
        color: var(--black60);
        font-weight: var(--medium-bold);
        font-size: var(--d-font-size);


        &.selected {
            color: var(--black);
            font-weight: var(--semi-bold);
        }


        .variantName {
            margin-left: var(--s-spacing);
        }


        .radio {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
 }


 .colorBox {
    margin: var(--l-spacing) 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    .color {
        border-radius: 10px;
        width: 35px;
        height: 35px;
        cursor: pointer;
        margin-right: var(--l-spacing);
        margin-bottom: var(--l-spacing);


        .check {
            position: relative;
            left: 16px;
            top: -6px;
        }
    }
 }


 .disabled {
    opacity: 0.5;
    pointer-events: none;
    touch-action: none;
    z-index: -1;
 }
