.main {
    border-bottom: 1px solid var(--black15);
    margin-bottom: var(--m-spacing);
    padding-bottom: var(--xl-spacing);

    @media (--phone) {
        padding-bottom: var(--l-spacing);
    }
}

.container {
    display: flex;
    flex-direction: row;

    @media (--desktop) {
        align-items: center;
    }
}


.right {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--desktop) {
        min-width: 108px;
        padding-bottom:0.25rem;
    }

    @media (--phone) {
        min-height: 100px;
        justify-content: center;
    }
}

.imgContainer {
    width: 110px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--s-spacing);
    border: 1px solid var(--black05) !important;
    border-radius: var(--s-border-radius);
    position: relative;

    @media (--desktop) {
        height: 110px;
        border-radius: 15px;
        /*z-index: -99999 !important*/
    }
}

.oos {
    position: absolute;
    font-weight: var(--medium-bold);
    font-size: var(--s-font-size);
    color: var(--secondary);
    padding: 2px var(--s-spacing);
    background: var(--black40);
    border: 1px solid var(--secondary);
    box-sizing: border-box;
    border-radius: 10px;
    top: 8px;
    left: 8px;

    @media (--desktop) {
        border-radius: 5px;
        padding: var(--s-spacing) var(--xl-spacing);
    }
}

.img {
    width: 108px;
    height: 88px;
    object-fit: cover;

    @media (--desktop) {
        height: 110px;
        width: 110px;
        border-radius: 15px;
    }
}

.click {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: var(--l-spacing);
}

.name {
    margin-top: var(--s-spacing);
    font-weight: var(--medium-bold);
    font-size: var(--d-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
    text-align: left;
}

.price {
    margin-bottom: var(--s-spacing);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.amount,
.discount {
    color: var(--primary);
    font-size: var(--m-font-size);
    font-weight: var(--semi-bold);
    margin-right: var(--s-spacing);
}

.originalAmount {
    font-size: var(--s-font-size);
    color: var(--black50);
    text-decoration-line: line-through;
    margin-right: var(--s-spacing);
}

.discount {
    color: var(--green);
    background: var(--green20);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    padding: 2px var(--s-spacing);
}

.unit {
    color: var(--black50);
    text-transform: capitalize;
    margin-bottom: var(--m-spacing);
}

.flexRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media (--desktop) {
        margin-right: 10px;
    }
}


