.cart {
    font-weight: var(--semi-bold);
    font-size: 32px;
    color: var(--black);
    padding-top: 40px;
}

.container {
    margin-bottom: 50px;
}

.borderLeft {
    border-left: 1px solid var(--black15);
    padding-left: 50px;
}

.items {
    color: var(--black60);
    font-size: var(--d-font-size);
    font-weight: var(--medium-bold);
    margin-top: var(--m-spacing);
    margin-bottom: var(--xl-spacing);
}

.productLeft {
    display: flex;
    flex-direction: column;
    flex: 0.8;
}

.product {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--l-spacing);
}

.pricing {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.title {
    font-weight: var(--medium-bold);
    font-size: var(--d-font-size);
    color: var(--black);
    margin-bottom: var(--s-spacing);
}

.amount {
    font-weight: var(--semi-bold);
    font-size: var(--d-font-size);
    color: var(--black);
}

.originalAmount {
    font-weight: var(--medium-bold);
    font-size: var(--m-font-size);
    margin: 0 var(--s-spacing);
    color: #FF4545;
    text-decoration-line: line-through;
}

.discount {
    font-weight: var(--medium-bold);
    font-size: var(--m-font-size);
    color: var(--green);
}

.unit {
    margin-top: var(--xs-spacing);
    font-size: var(--m-font-size);
    color: var(--black60);
}

.add {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
        font-size: var(--d-font-size);
        font-weight: var(--medium-bold);
        color: var(--primary);
        margin: 0 var(--s-spacing);
    }

    .minus {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 20px;
    }
}

.addButton {
    border: 1px solid var(--primary);
    display: inline-flex;
    min-width: 68px;
    min-height: 24px;
    align-items: center;
    justify-content: center;
    border-radius: var(--s-border-radius);
}


.bottom {
    padding-left: 50px;
    margin-top: var(--xl-spacing);
}

.subTotal {
    font-weight: var(--semi-bold);
    font-size: var(--h4-font-size);
    color: var(--black);
    display: flex;
    flex-direction: row;

    div:first-child {
        flex: 0.8;
    }
}

.extraCharges {
    color: var(--black60);
    font-size: var(--m-font-size);
    font-weight: var(--medium-bold);
    margin-top: var(--s-spacing);
    margin-bottom: var(--xxxxl-spacing);
}

.button {
    margin-left: 30px;
    width: 80%;
}
