.container {
    margin-top: var(--l-spacing);
}

.heading {
    font-size: var(--h4-font-size);
    color: var(--black);
    font-weight: var(--semi-bold);
}

.paper {
    border-radius: 20px 20px 0 0;
    max-height: 80% !important;
    overflow: hidden;
}

.overflow {
    overflow:  hidden !important;
    padding: 16px !important
}

.row {
    display: flex;
    align-items: center;
    align-content: center;
}

.closeIcon {
    padding-right: 12px;
}

.fIcon {
    margin-left: 10px;
        margin-top: 8px;
}

.sIcon {
    margin-left: 10px;
    margin-top: 4px;
}

.cIcon {
       position: fixed;
    right: 20px; 
    padding-top: 4px;
}

.bottom {
    padding-bottom: 52px !important;
}