.container {
    margin-top: var(--l-spacing);
    overflow-y: auto;
    height: 80%;
}

.heading {
    font-size: var(--d-font-size);
    font-weight: var(--normal);
    color: var(--primary);
}

.paper {
    border-radius: 20px 20px 0 0;
    max-height: 80% !important;
    overflow: hidden;
}

.overflow {
    overflow: hidden !important;
    padding: 16px !important;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeIcon {
    padding-right: 12px;
    color: var(--primary);
}

.fIcon {
    margin-left: 10px;
}
